

















import Vue from "vue";
import Component from "vue-class-component";

import FooterComponent from "@/components/FooterComponent.vue";

import { UiState } from "@/UiState";

@Component({
  components: {
    FooterComponent,
  },
})
export default class LayoutComponent extends Vue {
  uiState: UiState = UiState.getInstance();

  imgSrc(): string {
    return require("@/assets/" + this.uiState.theme + "/logo.png");
  }
}
