
















import Vue from "vue";
import Component from "vue-class-component";

import ApplicationLoadingComponent from "@/components/ApplicationLoadingComponent.vue";
import UserNotificationComponent from "@/components/UserNotificationComponent.vue";

@Component({
  components: {
    ApplicationLoadingComponent,
    UserNotificationComponent,
  },
})
export default class App extends Vue {}
