import { OCRRegistration, OCRChange, OCRChangeData } from "@/types/OCRTypes";
import Axios, { AxiosInstance } from "axios";
import { ApplicationService } from "../ApplicationService";

class OCRServiceProvider {
  private _service: OCRService;

  private constructor() {
    this._service = new OCRApiService();
  }

  private static _instance: OCRServiceProvider;

  static getInstance(): OCRServiceProvider {
    if (!OCRServiceProvider._instance) {
      OCRServiceProvider._instance = new OCRServiceProvider();
    }

    return OCRServiceProvider._instance;
  }

  getService(): OCRService {
    return this._service;
  }
}

interface OCRService {
  getRegistrationData(id: string): Promise<OCRRegistration>;
  confirmRegistration(id: string, mailSubscription: boolean): Promise<void>;
  getChangeData(id: string): Promise<OCRChange>;
  saveChanges(id: string, data: OCRChangeData): Promise<void>;
}

class OCRApiService implements OCRService {
  private _axios: AxiosInstance;

  constructor() {
    const url = ApplicationService.API_URL + "/api";

    this._axios = Axios.create({
      url: url,
      baseURL: url,
    });
  }

  getRegistrationData(id: string): Promise<OCRRegistration> {
    return new Promise<OCRRegistration>((resolve, reject) => {
      const url = "/registration/" + id;
      this._axios
        .get(url)
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }

  confirmRegistration(id: string, mailSubscription: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = "/registration/" + id;
      this._axios
        .put(url, {
          uuid: id,
          acceptDisclaimer: true,
          acceptGeneralTerms: true,
          acceptParticipation: true,
          mailSubscription: mailSubscription,
        })
        .then(() => resolve())
        .catch(reject);
    });
  }

  getChangeData(id: string): Promise<OCRChange> {
    return new Promise<OCRChange>((resolve, reject) => {
      const url = "/registration/" + id + "/user";
      this._axios
        .get(url)
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }

  saveChanges(id: string, data: OCRChangeData): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = "/registration/" + id + "/user";
      this._axios
        .put(url, data)
        .then(() => resolve())
        .catch(reject);
    });
  }
}

export { OCRServiceProvider, OCRService };
