import {
  defaultRegistration,
  defaultChange,
  OCRChange,
  OCRChangeState,
  OCRRegistration,
  OCRRegistrationState,
  FIRST_NAME_MIN_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  EMAIL_PATTERN,
  MOBILE_MAX_LENGTH,
  TEAM_NAME_MAX_LENGTH,
  COMMENT_MAX_LENGTH,
  OCRChangeData,
  OCRSex,
} from "@/types/OCRTypes";

interface UITheme {
  readonly hasTheme: boolean;
  theme: string;
}

class UIThemeImpl implements UITheme {
  private _theme: string;

  constructor() {
    this._theme = "";
  }

  get hasTheme(): boolean {
    return this._theme != null && this.theme.length > 0;
  }

  get theme(): string {
    return this._theme;
  }

  set theme(value: string) {
    this._theme = value;
  }
}

interface UIValidity {
  isValid: boolean;
}

class UIValidityImpl implements UIValidity {
  private _isValid: boolean;

  constructor() {
    this._isValid = false;
  }

  get isValid(): boolean {
    return this._isValid;
  }

  set isValid(value: boolean) {
    this._isValid = value;
  }
}

class BaseUIState implements UITheme, UIValidity {
  private _validity: UIValidity;
  private _theme: UITheme;

  constructor(validity: UIValidity, theme: UITheme) {
    this._validity = validity;
    this._theme = theme;
  }

  get hasTheme(): boolean {
    return this._theme.hasTheme;
  }

  get theme(): string {
    return this._theme.theme;
  }

  set theme(value: string) {
    this._theme.theme = value;
  }

  get isValid(): boolean {
    return this._validity.isValid;
  }

  set isValid(value: boolean) {
    this._validity.isValid = value;
  }
}

class UiState implements UITheme, UIValidity {
  private _validity: UIValidity;
  private _theme: UITheme;

  private _registration: RegistrationUiState;
  private _change: ChangeUiState;

  private constructor() {
    this._validity = new UIValidityImpl();
    this._theme = new UIThemeImpl();

    this._registration = new RegistrationUiState(this._validity, this._theme);
    this._change = new ChangeUiState(this._validity, this._theme);
  }

  get hasTheme(): boolean {
    return this._theme.hasTheme;
  }

  get theme(): string {
    return this._theme.theme;
  }

  set theme(value: string) {
    this._theme.theme = value;
  }

  private static _instance: UiState;

  static getInstance(): UiState {
    if (!UiState._instance) {
      UiState._instance = new UiState();
    }

    return UiState._instance;
  }

  get isValid(): boolean {
    return this._validity.isValid;
  }

  set isValid(value: boolean) {
    this._validity.isValid = value;
  }

  get registration(): RegistrationUiState {
    return this._registration;
  }

  get change(): ChangeUiState {
    return this._change;
  }
}

class RegistrationUiState extends BaseUIState implements UITheme, UIValidity {
  private _ocrRegistration: OCRRegistration;

  constructor(validity: UIValidity, theme: UITheme) {
    super(validity, theme);
    this._ocrRegistration = defaultRegistration();
  }

  get ocrRegistration(): OCRRegistration {
    return this._ocrRegistration;
  }

  set ocrRegistration(value: OCRRegistration) {
    this._ocrRegistration = value;
    this.isValid = true;
  }

  get name(): string {
    if (this._ocrRegistration === undefined) return "";
    return this._ocrRegistration.name;
  }

  get email(): string {
    if (this._ocrRegistration === undefined) return "";
    return this._ocrRegistration.email;
  }

  get disclaimerLink(): string {
    if (this._ocrRegistration === undefined) return "";
    return this._ocrRegistration.theme.disclaimerLink;
  }

  get generalTermsAndConditionsLink(): string {
    if (this._ocrRegistration === undefined) return "";
    return this._ocrRegistration.theme.generalTermsAndConditionsLink;
  }

  get conditionOfParticipationLink(): string {
    if (this._ocrRegistration === undefined) return "";
    return this._ocrRegistration.theme.conditionOfParticipationLink;
  }

  get canRegister(): boolean {
    if (this._ocrRegistration === undefined) return false;

    return OCRRegistrationState.New === this._ocrRegistration.status;
  }
}

class ChangeUiState extends BaseUIState implements UITheme, UIValidity {
  private _ocrChange: OCRChange;
  private _form: ChangeForm;

  private _name: string | null;
  private _email: string | null;

  constructor(validity: UIValidity, theme: UITheme) {
    super(validity, theme);

    this._form = new ChangeForm();
    this._ocrChange = defaultChange();

    this._name = null;
    this._email = null;
  }

  get ocrChange(): OCRChange {
    return this._ocrChange;
  }

  set ocrChange(value: OCRChange) {
    this._ocrChange = value;

    this.updateName();
    this.updateEmail();

    this._form.update(value);

    this.isValid = true;
  }

  get form(): ChangeForm {
    return this._form;
  }

  get name(): string | null {
    return this._name;
  }

  get email(): string | null {
    return this._email;
  }

  private updateName(): void {
    this._name = null;

    if (this._ocrChange == null || this.ocrChange.userChange == null) return;

    const data = this.ocrChange.userChange;
    this._name = data.firstName + " " + data.lastName;
  }

  private updateEmail(): void {
    this._email = null;

    if (this._ocrChange == null || this.ocrChange.userChange == null) return;

    this._email = this.ocrChange.userChange.email;
  }

  getChangeData(): OCRChangeData {
    if (!this.form.isValid) throw Error("invalid state");

    return this.form.getChangeData();
  }
}

class ChangeForm {
  private _readonly: boolean;
  private _canChangeEmail: boolean;

  private _originalData: OCRChangeData | null;
  private _originalDataJson: string;

  private _firstName: string;
  private _lastName: string;
  private _sex: OCRSex;

  private _dayOfBirth: string;
  private _dayOfBirthActivePicker: string;

  private _email: string;
  private _mobile: string;
  private _teamname: string;
  private _comment: string;

  constructor() {
    this._readonly = true;
    this._canChangeEmail = false;

    this._originalData = null;
    this._originalDataJson = "";

    this._firstName = "";
    this._lastName = "";
    this._sex = OCRSex.Other;
    this._dayOfBirth = "";
    this._dayOfBirthActivePicker = "YEAR";
    this._email = "";
    this._mobile = "";
    this._teamname = "";
    this._comment = "";
  }

  get readonly(): boolean {
    return this._readonly;
  }

  get canChangeEmail(): boolean {
    return this._canChangeEmail;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get sex(): OCRSex {
    return this._sex;
  }

  set sex(value: OCRSex) {
    this._sex = value;
  }

  get dayOfBirth(): string {
    return this._dayOfBirth;
  }

  set dayOfBirth(value: string) {
    this._dayOfBirth = value;
  }

  get dayOfBirthLocalized(): string {
    if (this.dayOfBirth == null) return "";

    return new Date(this.dayOfBirth).toLocaleString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  get dayOfBirthActivePicker(): string {
    return this._dayOfBirthActivePicker;
  }

  set dayOfBirthActivePicker(value: string) {
    this._dayOfBirthActivePicker = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get mobile(): string {
    return this._mobile;
  }

  set mobile(value: string) {
    this._mobile = value;
  }

  get teamname(): string {
    return this._teamname;
  }

  set teamname(value: string) {
    this._teamname = value;
  }

  get comment(): string {
    return this._comment;
  }

  set comment(value: string) {
    this._comment = value;
  }

  reset(): void {
    this._readonly = true;
    this._canChangeEmail = false;

    this._originalData = null;
    this._originalDataJson = "";

    this._firstName = "";
    this._lastName = "";
    this._sex = OCRSex.Other;
    this._dayOfBirth = "";
    this._dayOfBirthActivePicker = "YEAR";
    this._email = "";
    this._mobile = "";
    this._teamname = "";
    this._comment = "";
  }

  update(originalData: OCRChange): void {
    this.reset();

    this._readonly = OCRChangeState.InReview === originalData.status;
    this._canChangeEmail = !this._readonly && originalData.allowEmailChange;

    const userChange = originalData.userChange;
    this._originalData = userChange;
    this._originalDataJson = JSON.stringify(userChange);

    this.firstName = userChange.firstName;
    this.lastName = userChange.lastName;
    this.sex = userChange.sex;
    this.dayOfBirth = userChange.dayOfBirth;
    this.email = userChange.email;
    this.mobile = userChange.mobile;
    this.teamname = userChange.teamname;
    this.comment = userChange.comment;
  }

  isValid(): boolean {
    if (
      this.firstName === undefined ||
      this.firstName === null ||
      this.firstName.trim().length < FIRST_NAME_MIN_LENGTH ||
      this.firstName.length > FIRST_NAME_MAX_LENGTH
    )
      return false;
    if (
      this.lastName === undefined ||
      this.lastName === null ||
      this.lastName.trim().length < LAST_NAME_MIN_LENGTH ||
      this.lastName.length > LAST_NAME_MAX_LENGTH
    )
      return false;
    if (this.sex === undefined || this.sex === null) return false;
    if (this.dayOfBirth === undefined || this.dayOfBirth === null) return false;

    if (
      this.email === undefined ||
      this.email === null ||
      this.lastName.length > EMAIL_MAX_LENGTH ||
      !EMAIL_PATTERN.test(this.email)
    )
      return false;

    if (
      this.mobile !== undefined &&
      this.mobile !== null &&
      this.mobile.length > MOBILE_MAX_LENGTH
    )
      return false;
    if (
      this.teamname !== undefined &&
      this.teamname !== null &&
      this.teamname.length > TEAM_NAME_MAX_LENGTH
    )
      return false;
    if (
      this.comment !== undefined &&
      this.comment !== null &&
      this.comment.length > COMMENT_MAX_LENGTH
    )
      return false;

    return true;
  }

  get hasChanges(): boolean {
    if (!this.isValid) return false;

    if (this._originalData === undefined || this._originalData === null)
      return false;
    if (this._originalDataJson === undefined || this._originalDataJson === null)
      return false;

    return this._originalDataJson !== JSON.stringify(this.getChangeData());
  }

  getChangeData(): OCRChangeData {
    if (!this.isValid) throw Error("invalid state");

    return {
      uuid: this._originalData!.uuid,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      sex: this.sex,
      dayOfBirth: this.dayOfBirth,
      mobile: this.mobile,
      teamname: this.teamname,
      comment: this.comment,
    };
  }
}

export { UiState, RegistrationUiState, ChangeUiState, ChangeForm };
