





















































































































































































import Vue from "vue";
import Component from "vue-class-component";

import LayoutComponent from "@/components/Layout.vue";

import { initializeTheme } from "@/utils/ThemeUtil";
import { getUserId } from "@/utils/ParameterUtil";
import { UiState, RegistrationUiState } from "@/UiState";
import { OCRServiceProvider } from "@/services/remote/OcrService";
import { NotificationService } from "@/services/NotificationService";

@Component({
  components: {
    LayoutComponent,
  },
})
export default class HomeView extends Vue {
  showView = false;

  uiState: RegistrationUiState = UiState.getInstance().registration;

  termsAndConditions = false;
  regulations = false;
  generalTermsAndConditions = false;
  mailSubscription = false;

  get sendBtnDisabled(): boolean {
    if (this.termsAndConditions === false) return true;
    if (this.regulations === false) return true;
    if (this.generalTermsAndConditions === false) return true;

    return false;
  }

  onTermsAndConditionsClickedHanlder(): void {
    this.termsAndConditions = !this.termsAndConditions;
  }

  onRegulationsClickedHandler(): void {
    this.regulations = !this.regulations;
  }

  onGeneralTermsAndConditionsClickedHandler(): void {
    this.generalTermsAndConditions = !this.generalTermsAndConditions;
  }

  onMailSubscriptionClickedHandler(): void {
    this.mailSubscription = !this.mailSubscription;
  }

  onSendBtnClickedHandler(): void {
    this.confirmRegistration();
  }

  private confirmRegistration(): void {
    const notificationService = NotificationService.getInstance();
    notificationService
      .indicateLoading(
        this.confirmRegistrationViaApi(),
        this.$t("messages.sendingData") as string
      )
      .then(() => {
        this.loadData();
      })
      .catch((err: Error) => {
        notificationService.notifyError(err.message);
      });
  }

  private confirmRegistrationViaApi(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const ocrService = OCRServiceProvider.getInstance().getService();
      ocrService
        .confirmRegistration(
          this.uiState.ocrRegistration.uuid,
          this.mailSubscription
        )
        .then(resolve)
        .catch(reject);
    });
  }

  loadDataFromApi(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      getUserId(this)
        .then((userId) => {
          const ocrService = OCRServiceProvider.getInstance().getService();
          ocrService
            .getRegistrationData(userId)
            .then((data) => {
              this.uiState.ocrRegistration = data;
              resolve();
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  loadData(): void {
    const notificationService = NotificationService.getInstance();
    notificationService
      .indicateLoading(
        this.loadDataFromApi(),
        this.$t("messages.loadingData") as string,
        true
      )
      .then(() => {
        this.showView = true;
      })
      .catch((err: Error) => {
        this.$router.push("/404").catch((err) => {
          console.log("required param missing");
        });
      });
  }

  mounted(): void {
    initializeTheme(this);
    this.loadData();
  }
}
