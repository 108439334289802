










import Vue from "vue";
import Component from "vue-class-component";

import { EventBus } from "@/types/EventBus";
import { LoadingInformation } from "@/types/CustomUiDataTypes";

import {
  LOADING_INFORMATION_EVENT,
  LOADING_INFORMATION_RESET_EVENT,
} from "@/types/EventDefinitions";

@Component({
  components: {},
})
export default class ApplicationLoadingComponent extends Vue {
  isRegistered = false;
  isVisible = false;
  isAbsolute = true;

  message: string | null = null;

  private onLoadingInformationEvent(event: LoadingInformation) {
    this.message = event.message;
    this.isVisible = true;
  }

  private onLoadingInformationResetEvent() {
    this.message = null;
    this.isVisible = false;
  }

  mounted(): void {
    if (this.isRegistered) return;

    EventBus.getInstance().on(
      LOADING_INFORMATION_EVENT,
      (event: LoadingInformation) => {
        this.onLoadingInformationEvent(event);
      }
    );

    EventBus.getInstance().on(LOADING_INFORMATION_RESET_EVENT, () => {
      this.onLoadingInformationResetEvent();
    });

    this.isRegistered = true;
  }
}
