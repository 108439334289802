
















































import Vue from "vue";
import Component from "vue-class-component";

import { UiState } from "@/UiState";

@Component({
  components: {},
})
export default class FooterComponent extends Vue {
  get socialIconMaxWidth(): string {
    return "32px";
  }

  uiState: UiState = UiState.getInstance();

  imgSrcFb(): string {
    return require("@/assets/" + this.uiState.theme + "/sm_fb.png");
  }

  imgSrcIn(): string {
    return require("@/assets/" + this.uiState.theme + "/sm_in.png");
  }

  imgSrcYt(): string {
    return require("@/assets/" + this.uiState.theme + "/sm_yt.png");
  }

  imgSrcTw(): string {
    return require("@/assets/" + this.uiState.theme + "/sm_tw.png");
  }

  private getThemeValueFor(valueKey: string): string {
    if (!this.uiState.hasTheme) return "";

    const key = "theme." + this.uiState.theme + "." + valueKey;
    if (!this.$te(key)) return "";

    return this.$t(key) as string;
  }

  get shopLink(): string {
    return this.getThemeValueFor("shop_link");
  }

  get socialLink(): string {
    return this.getThemeValueFor("social_link");
  }

  get facebookLink(): string {
    return this.getThemeValueFor("facebook_link");
  }

  get instagramLink(): string {
    return this.getThemeValueFor("instagram_link");
  }

  get youtubeLink(): string {
    return this.getThemeValueFor("youtube_link");
  }

  get twitterLink(): string {
    return this.getThemeValueFor("twitter_link");
  }
}
