import Vue from "vue";
import { UiState } from "@/UiState";

function initializeTheme(view: Vue): void {
  const uiState = UiState.getInstance();
  const hostname = window.location.hostname.replaceAll(".", "_");

  const key = "themeMap." + hostname;
  const theme = view.$te(key) ? (view.$t(key) as string) : "";
  uiState.theme = theme;

  document.title = uiState.hasTheme
    ? (view.$t("theme." + uiState.theme + ".title") as string)
    : (view.$t("themeDefaults.title") as string);
}

export { initializeTheme };
