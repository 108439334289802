import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";

import "./assets/reset.css";
import { ApplicationService } from "./services/ApplicationService";

Vue.config.productionTip = false;

ApplicationService.init()
  .then(() => {
    new Vue({
      i18n,
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((err) => {
    console.log(err.message);
  });
