import Vue from "vue";

function getUserId(view: Vue): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const userId = extractUserIdFromParameters(view);
    if (userId === null) {
      reject(
        new Error(
          view.$t("errors.requiredParameterMissing", {
            parameterName: "user",
          }) as string
        )
      );
      return;
    }

    resolve(userId);
  });
}

function extractUserIdFromParameters(view: Vue): string | null {
  const queryParameters = view.$route.query;
  if (queryParameters == null) return null;

  const parameters = queryParameters["user"];
  if (parameters === undefined || parameters === null) {
    return null;
  }

  const value = parameters as string;
  if (value !== undefined && value !== null) {
    if (value.length === 0) return null;

    const regexp = new RegExp(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
    );
    if (regexp.test(value)) {
      return decodeURIComponent(value);
    }
  }

  return null;
}

export { getUserId };
