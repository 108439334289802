enum OCRRegistrationState {
  New = "NEW",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
}

enum OCRChangeState {
  Allowed = "ALLOWED",
  InReview = "IN_REVIEW",
}

enum OCRSex {
  Male = "Männlich",
  Female = "Weiblich",
  Other = "Divers",
}

interface OCRRegistration {
  uuid: string;
  status: OCRRegistrationState;
  name: string;
  email: string;
  theme: OCRTheme;
}

interface OCRChange {
  status: OCRChangeState;
  allowEmailChange: boolean;
  userChange: OCRChangeData;
  theme: OCRTheme;
}

interface OCRChangeData {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  sex: OCRSex;
  dayOfBirth: string;
  mobile: string;
  teamname: string;
  comment: string;
}

interface OCRTheme {
  theme: string;
  plattformName: string;
  contactMail: string;
  website: string;
  facebook: string;
  insta: string;
  twitter: string;
  youtube: string;
  disclaimerLink: string;
  generalTermsAndConditionsLink: string;
  conditionOfParticipationLink: string;
}

function defaultTheme(): OCRTheme {
  return {
    theme: "",
    plattformName: "",
    contactMail: "",
    website: "",
    facebook: "",
    insta: "",
    twitter: "",
    youtube: "",
    disclaimerLink: "",
    generalTermsAndConditionsLink: "",
    conditionOfParticipationLink: "",
  };
}

function defaultRegistration(): OCRRegistration {
  return {
    name: "",
    email: "",
    status: OCRRegistrationState.Canceled,
    theme: defaultTheme(),
    uuid: "",
  };
}

function defaultChange(): OCRChange {
  return {
    status: OCRChangeState.InReview,
    allowEmailChange: false,
    userChange: {
      uuid: "",
      firstName: "",
      lastName: "",
      email: "",
      sex: OCRSex.Other,
      dayOfBirth: "",
      mobile: "",
      teamname: "",
      comment: "",
    },
    theme: defaultTheme(),
  };
}

const FIRST_NAME_MIN_LENGTH = 2;
const FIRST_NAME_MAX_LENGTH = 255;
const LAST_NAME_MIN_LENGTH = 2;
const LAST_NAME_MAX_LENGTH = 255;
const EMAIL_MAX_LENGTH = 255;
const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const MOBILE_MAX_LENGTH = 255;
const TEAM_NAME_MAX_LENGTH = 255;
const COMMENT_MAX_LENGTH = 2000;

export {
  OCRRegistration,
  OCRRegistrationState,
  OCRTheme,
  OCRChangeData,
  OCRChange,
  OCRChangeState,
  OCRSex,
  defaultRegistration,
  defaultChange,
  FIRST_NAME_MIN_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  EMAIL_PATTERN,
  MOBILE_MAX_LENGTH,
  TEAM_NAME_MAX_LENGTH,
  COMMENT_MAX_LENGTH,
};
